
import { separateOptionalPaths } from '@virtuslab/nfs-shared/src/services/routes';
import type { RoutesToRoutesParams } from '@virtuslab/nfs-shared/src/services/routes';

export const BaseRoutes = separateOptionalPaths({
  BASE: '/',
} as const);
export type BaseRoutesProps = RoutesToRoutesParams<typeof BaseRoutes>;

export const LoginRoutes = separateOptionalPaths({
  LOGIN: '/auth/login',
} as const);
export type LoginRoutesProps = RoutesToRoutesParams<typeof LoginRoutes>;

export const LogoutRoutes = separateOptionalPaths({
  LOGOUT: '/auth/logout',
} as const);
export type LogoutRoutesProps = RoutesToRoutesParams<typeof LogoutRoutes>;

export const BenefitsRoutes = separateOptionalPaths({
  BENEFITS: '/benefits',
  BENEFITS_PRODUCTS: '/benefits/products',
  BENEFITS_SETTLEMENTS: '/benefits/settlements',
} as const);
export type BenefitsRoutesProps = RoutesToRoutesParams<typeof BenefitsRoutes>;

export const CompaniesRoutes = separateOptionalPaths({
  COMPANIES: '/companies',
} as const);
export type CompaniesRoutesProps = RoutesToRoutesParams<typeof CompaniesRoutes>;

export const CompanyDetailsRoutes = separateOptionalPaths({
  DETAILS: '/company/:companyId',
  CLIENT_CONTRACTS: '/company/:companyId/client-contract/:clientContractId?',
  DOCUMENTS: '/company/:companyId/documents',
} as const);
export type CompanyDetailsRoutesProps = RoutesToRoutesParams<typeof CompanyDetailsRoutes>;

export const ContractsRoutes = separateOptionalPaths({
  CONTRACTS: '/contracts',
} as const);
export type ContractsRoutesProps = RoutesToRoutesParams<typeof ContractsRoutes>;

export const DashboardRoutes = separateOptionalPaths({
  DASHBOARD: '/dashboard',
} as const);
export type DashboardRoutesProps = RoutesToRoutesParams<typeof DashboardRoutes>;

export const PeopleRoutes = separateOptionalPaths({
  PEOPLE: '/people',
} as const);
export type PeopleRoutesProps = RoutesToRoutesParams<typeof PeopleRoutes>;

export const PersonDetailsRoutes = separateOptionalPaths({
  DETAILS: '/person/:userId',
  CONTRACTS_AND_BENEFITS: '/person/:userId/contracts-and-benefits/:contractId?',
  PROJECTS_AND_ACTIVITIES: '/person/:userId/projects-and-activities',
  DOCUMENTS: '/person/:userId/documents',
  FRIENDS_AND_FAMILY: '/person/:userId/friends-and-family',
  PROFILE: '/person/:userId/profile',
  PROGRESSION: '/person/:userId/progression/:contractId?',
  ANNUAL_LEAVE: '/person/:userId/annual-leave/:contractId?',
  ANNUAL_LEAVE_SUMMARY: '/person/:userId/annual-leave/:contractId/summary',
  ANNUAL_LEAVE_ENTITLEMENT: '/person/:userId/annual-leave/:contractId/entitlement',
  TIME_REPORTS_CONTRACT: '/person/:userId/time-reports',
  TIME_REPORTS_CONTRACT_MONTHLY: '/person/:userId/time-reports/:contractId/:year/:month',
  TIME_REPORTS_CONTRACT_MONTHLY_CALENDAR: '/person/:userId/time-reports/:contractId/:year/:month/calendar',
  TIME_REPORTS_CONTRACT_MONTHLY_LEAVE_AND_TRAINING: '/person/:userId/time-reports/:contractId/:year/:month/leave_and_training',
  TIME_REPORTS_CONTRACT_MONTHLY_WORK_TIME: '/person/:userId/time-reports/:contractId/:year/:month/work_time',
  TIME_REPORTS_CONTRACT_MONTHLY_FINANCES: '/person/:userId/time-reports/:contractId/:year/:month/finances',
  TIME_REPORTS_CONTRACT_MONTHLY_CALENDAR_WITH_DAY: '/person/:userId/time-reports/:contractId/:year/:month/calendar/:day',
  TIME_REPORTS_CONTRACT_MONTHLY_CALENDAR_WITH_ENTRY: '/person/:userId/time-reports/:contractId/:year/:month/:day/calendar/:entryId',
} as const);
export type PersonDetailsRoutesProps = RoutesToRoutesParams<typeof PersonDetailsRoutes>;

export const ProjectsRoutes = separateOptionalPaths({
  PROJECTS: '/projects',
} as const);
export type ProjectsRoutesProps = RoutesToRoutesParams<typeof ProjectsRoutes>;

export const ProjectDetailsRoutes = separateOptionalPaths({
  DETAILS: '/project/:projectId',
  ABOUT: '/project/:projectId/about',
  MEMBERS: '/project/:projectId/members',
} as const);
export type ProjectDetailsRoutesProps = RoutesToRoutesParams<typeof ProjectDetailsRoutes>;

export const ReportsRoutes = separateOptionalPaths({
  REPORTS_REMINDERS: '/reports/reminders',
  REPORTS: '/reports',
} as const);
export type ReportsRoutesProps = RoutesToRoutesParams<typeof ReportsRoutes>;

export const SettingsRoutes = separateOptionalPaths({
  SETTINGS: '/settings',
} as const);
export type SettingsRoutesProps = RoutesToRoutesParams<typeof SettingsRoutes>;

export const SpaceDetailsRoutes = separateOptionalPaths({
  DETAILS: '/space/:spaceId',
} as const);
export type SpaceDetailsRoutesProps = RoutesToRoutesParams<typeof SpaceDetailsRoutes>;

export const SpacesRoutes = separateOptionalPaths({
  SPACES: '/spaces',
} as const);
export type SpacesRoutesProps = RoutesToRoutesParams<typeof SpacesRoutes>;

export const SalariesRoutes = separateOptionalPaths({
  BASE: '/salaries',
  SALARIES: '/salaries/:year',
} as const);
export type SalariesRoutesProps = RoutesToRoutesParams<typeof SalariesRoutes>;

export const SalariesDetailsRoutes = separateOptionalPaths({
  EMPLOYMENT: '/transfers/:year/:month/employment',
  B2B: '/transfers/:year/:month/b2b',
} as const);
export type SalariesDetailsRoutesProps = RoutesToRoutesParams<typeof SalariesDetailsRoutes>;

export const NotFoundRoutes = separateOptionalPaths({
  NOT_FOUND: '/404',
  FALLBACK: '*',
});
export type NotFoundRoutesProps = RoutesToRoutesParams<typeof NotFoundRoutes>;

export type AnyRouteObject = typeof BenefitsRoutes
| typeof CompaniesRoutes
| typeof CompanyDetailsRoutes
| typeof ContractsRoutes
| typeof DashboardRoutes
| typeof DashboardRoutes
| typeof NotFoundRoutes
| typeof PeopleRoutes
| typeof PersonDetailsRoutes
| typeof ProjectsRoutes
| typeof ReportsRoutes
| typeof SalariesDetailsRoutes
| typeof SalariesRoutes
| typeof SettingsRoutes
| typeof SpaceDetailsRoutes
| typeof SpacesRoutes;

export type RouteAccessChecker = (root: AnyRouteObject) => boolean;
