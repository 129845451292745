export enum IsStudent {
  True = 'True',
  NotApplicable = '',
}

export enum SubordinateType {
  Operational = 'operational',
  Practice = 'practice',
  OperationalOrPractice = 'operational_or_practice',
  Space = 'space',
}

export enum ProjectMemberStatus {
  active = 'active',
  future = 'future',
}

export enum TimeReportStatus {
  correct = 'correct',
  incomplete = 'incomplete',
  requiresApproval = 'requiresApproval',
}

export default {};
