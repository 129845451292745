const heights = {
  max: {
    dropdown: '35rem',
    incomeModalBenefitVariants: '50vh',
  },
  min: {
    timeReportDay: '13.8rem',
    salariesDataGroupHeader: '3.9rem',
  },
  default: {
    datePickerYear: '3.6rem',
    annualLeaveRow: '4.8rem',
    annualLeaveRowExtended: '6.4rem',
    timeReportStatsColumn: '5.2rem',
    datePickerHighlight: '.4rem',
    datePickerCell: '3.6rem',
    datePickerDays: '21.8rem',
    datePickerYears: '25.4rem',
    datePickerMonths: '15.4rem',
    entityListToolbar: '7.8rem',
    salariesListToolbar: '14rem',
    drawerPartialLoadingError: '47rem',
    workingDayCheckbox: '3.6rem',
    clientContractPositionField: '3.2rem',
  },
};

export type Height = keyof typeof heights;

export default heights;
