import merge from 'lodash/fp/merge';
import set from 'lodash/fp/set';

import {
  B2BContractType, BenefitGroupType,
  CompanyType,
  ContractStatus,
  EmploymentContractType,
  FilterContractType,
  IdType,
  ProgressionLevel,
  Settlement,
  SortCompany,
  BenefitBuyerType,
  OnCallMultiplier,
  PaymentStatus,
  CalendarDayType,
  AbsenceType,
  BenefitBillingPeriod,
  BenefitInvoiceCategory,
  ContractType,
  AdditionalPeopleNumber,
  ContractDocumentTemplateLanguage,
  Language,
  Quarter,
  BonusType,
  AbsenceTypeInput,
  VatNotAppliedReason,
  HolidayCalendarId,
  CompanyRole,
  DeductibleCostsType,
} from '@virtuslab/nfs-shared/src/schema/admin';
import { parsePluralMessageBlocks, parsePluralMessages } from '@virtuslab/nfs-shared/src/services/i18n/messages';

import {
  IsStudent,
} from '../../additionalTypes';
import { PendingStateDescription } from '../../salaries';

import messages, { enumMessage, pluralBuildingBlocks, pluralMessages } from '../messages';

import type { DefaultLanguage } from '.';

const stringToObject = <T extends string>(arg: T) => set<Record<T, T>>(arg, arg, {});

const translations: DefaultLanguage = {
  ...messages.map(stringToObject).reduce(merge),
  ...parsePluralMessages(pluralMessages),
  ...parsePluralMessageBlocks(pluralBuildingBlocks),
  [enumMessage('deductibleCostType', DeductibleCostsType.COPYRIGHT)]: 'Copyright',
  [enumMessage('deductibleCostType', DeductibleCostsType.NORMAL)]: '20%',
  [enumMessage('deductibleCostType', DeductibleCostsType.RAISED)]: '50%',
  [enumMessage('employmentContractType', EmploymentContractType.UOP)]: 'UOP',
  [enumMessage('employmentContractType', EmploymentContractType.UOD)]: 'UOD',
  [enumMessage('employmentContractType', EmploymentContractType.UZ)]: 'UZ',
  [enumMessage('employmentContractType', EmploymentContractType.MANAGEMENT)]: 'Management',
  [enumMessage('settlement', Settlement.DAILY)]: 'Daily',
  [enumMessage('settlement', Settlement.HOURLY)]: 'Hourly',
  [enumMessage('settlement', Settlement.MONTHLY)]: 'Monthly',
  [enumMessage('progressionLevel', ProgressionLevel.ENGINEERINTERN)]: 'Intern',
  [enumMessage('progressionLevel', ProgressionLevel.NONENGINEERINTERN)]: 'Intern',
  [enumMessage('progressionLevel', ProgressionLevel.ENGINEERJUNIOR)]: 'Junior',
  [enumMessage('progressionLevel', ProgressionLevel.NONENGINEERJUNIOR)]: 'Junior',
  [enumMessage('progressionLevel', ProgressionLevel.ENGINEERMID)]: 'Mid',
  [enumMessage('progressionLevel', ProgressionLevel.NONENGINEERREGULAR)]: 'Regular',
  [enumMessage('progressionLevel', ProgressionLevel.ENGINEERSENIOR)]: 'Senior',
  [enumMessage('progressionLevel', ProgressionLevel.NONENGINEERSENIOR)]: 'Senior',
  [enumMessage('progressionLevel', ProgressionLevel.ENGINEERSTAFF)]: 'Staff',
  [enumMessage('progressionLevel', ProgressionLevel.NONENGINEEREXPERT)]: 'Expert',
  [enumMessage('progressionLevel', ProgressionLevel.ENGINEERPRINCIPAL)]: 'Principal',
  [enumMessage('contractStatus', ContractStatus.CONCLUDED)]: 'Signed',
  [enumMessage('contractStatus', ContractStatus.TERMINATED)]: 'Terminated',
  [enumMessage('contractStatus', ContractStatus.ACTIVE)]: 'Active',
  [enumMessage('idType', IdType.IDCARD)]: 'ID card',
  [enumMessage('idType', IdType.PASSPORT)]: 'Passport',
  [enumMessage('companyType', CompanyType.CLIENT)]: 'Client',
  [enumMessage('companyType', CompanyType.GROUP)]: 'Group',
  [enumMessage('companyType', CompanyType.B2B)]: 'B2B',
  [enumMessage('companyType', CompanyType.SUPPLIER)]: 'Supplier',
  [enumMessage('companyType', CompanyType.CAPITALGROUP)]: 'Capital group',
  [enumMessage('sortCompany', SortCompany.REGISTERID)]: 'Register Id',
  [enumMessage('sortCompany', SortCompany.REGON)]: 'Regon',
  [enumMessage('sortCompany', SortCompany.TAXNUMBER)]: 'Tax Number',
  [enumMessage('sortCompany', SortCompany.NAME)]: 'Name',
  [enumMessage('sortCompany', SortCompany.CREATIONDATE)]: 'Creation date',
  [enumMessage('b2bContractType', B2BContractType.B2B)]: 'B2B',
  [enumMessage('b2bContractType', B2BContractType.SUBCONTRACT)]: 'Sub',
  [enumMessage('filterContractType', FilterContractType.B2B)]: 'B2B',
  [enumMessage('filterContractType', FilterContractType.SUBCONTRACT)]: 'Subcontract',
  [enumMessage('filterContractType', FilterContractType.MANAGEMENT)]: 'Management',
  [enumMessage('filterContractType', FilterContractType.UOP)]: 'UOP',
  [enumMessage('filterContractType', FilterContractType.UOD)]: 'UOD',
  [enumMessage('filterContractType', FilterContractType.UZ)]: 'UZ',
  [enumMessage('isStudent', IsStudent.True)]: 'Yes',
  [enumMessage('isStudent', IsStudent.NotApplicable)]: 'No or not applicable',
  [enumMessage('benefitGroupType', BenefitGroupType.HEALTH)]: 'Health',
  [enumMessage('benefitGroupType', BenefitGroupType.OFFICE)]: 'Office',
  [enumMessage('benefitGroupType', BenefitGroupType.SPORT)]: 'Sport',
  [enumMessage('benefitBuyerType', BenefitBuyerType.PROMISEE)]: 'Promisee',
  [enumMessage('benefitBuyerType', BenefitBuyerType.PROMISOR)]: 'Promisor',
  [enumMessage('benefitBuyerType', BenefitBuyerType.THIRDPARTY)]: '3rd party',
  [enumMessage('contractDocumentTemplateLanguage', ContractDocumentTemplateLanguage.EN)]: 'English',
  [enumMessage('contractDocumentTemplateLanguage', ContractDocumentTemplateLanguage.PL)]: 'Polish',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.ONE)]: '1',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.TWO)]: '2',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.THREE)]: '3',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.FOUR)]: '4',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.MORE)]: 'More than 4',
  [enumMessage('pendingStateDescription', PendingStateDescription.ACCOUNTINGDATAMISSING)]: 'Missing payroll details',
  [enumMessage('pendingStateDescription', PendingStateDescription.INVOICEMISSING)]: 'Invoice missing',
  [enumMessage('pendingStateDescription', PendingStateDescription.INVOICEUNCHECKED)]: 'Invoice unverified',
  [enumMessage('pendingStateDescription', PendingStateDescription.TIMEREPORTINCOMPLETE)]: 'Time report incomplete',
  [enumMessage('pendingStateDescription', PendingStateDescription.WHITELISTINVALID)]: 'Not verified on whitelist',
  [enumMessage('pendingStateDescription', PendingStateDescription.NOBANKACCOUNT)]: 'No bank account',
  [enumMessage('pendingStateDescription', PendingStateDescription.TIMEREPORTREQUIRESAPPROVAL)]: 'Time report requires approval',
  [enumMessage('pendingStateDescription', PendingStateDescription.INVOICEFILEMISSING)]: 'Missing invoice file',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.INVOICEMISSING)]: 'Invoice(s) missing',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.INVOICEUNCHECKED)]: 'Invoice(s) unverified',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.TIMEREPORTINCOMPLETE)]: 'Time report(s) incomplete',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.TIMEREPORTREQUIRESAPPROVAL)]: 'Time report(s) require approval',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.WHITELISTINVALID)]: 'Not verified on whitelist',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.NOBANKACCOUNT)]: 'No bank account(s)',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.ACCOUNTINGDATAMISSING)]: 'Missing payroll details',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.INVOICEFILEMISSING)]: 'Missing invoice file(s)',
  [enumMessage('onCallMultiplier', OnCallMultiplier.ONE)]: 'x1',
  [enumMessage('onCallMultiplier', OnCallMultiplier.ONEANDHALF)]: 'x1.5',
  [enumMessage('onCallMultiplier', OnCallMultiplier.THREE)]: 'x3',
  [enumMessage('paymentStatus', PaymentStatus.DONE)]: 'Done',
  [enumMessage('paymentStatus', PaymentStatus.PENDING)]: 'Pending',
  [enumMessage('paymentStatus', PaymentStatus.READY)]: 'Ready',
  [enumMessage('calendarDayType', CalendarDayType.HOLIDAY)]: 'Public holiday',
  [enumMessage('calendarDayType', CalendarDayType.FREEDAYFOROTHERDAY)]: 'Virtuslab holiday',
  [enumMessage('absenceType', AbsenceType.SICKLEAVE)]: 'Sick leave',
  [enumMessage('absenceType', AbsenceType.OUTOFOFFICE)]: 'Out of office',
  [enumMessage('absenceType', AbsenceType.ANNUALLEAVE)]: 'Annual leave',
  [enumMessage('absenceType', AbsenceType.UNPAIDLEAVE)]: 'Unpaid leave',
  [enumMessage('absenceType', AbsenceType.SPECIALLEAVE)]: 'Special leave',
  [enumMessage('absenceType', AbsenceType.FLEXTIMELEAVE)]: 'Flextime leave',
  [enumMessage('absenceType', AbsenceType.MATERNITYLEAVE)]: 'Maternity leave',
  [enumMessage('absenceType', AbsenceType.PAIDLEAVE)]: 'Paid leave',
  [enumMessage('absenceType', AbsenceType.DUETOFORCEMAJEURELEAVE)]: 'Unexpected event leave',
  [enumMessage('absenceType', AbsenceType.CHILDCARELEAVE)]: 'Child care leave',
  [enumMessage('absenceType', AbsenceType.CAREGIVINGLEAVE)]: 'Caregiving leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.SICKLEAVE)]: 'Sick leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.OUTOFOFFICE)]: 'Out of office',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.ANNUALLEAVE)]: 'Annual leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.UNPAIDLEAVE)]: 'Unpaid leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.SPECIALLEAVE)]: 'Special leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.FLEXTIMELEAVE)]: 'Flextime leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.MATERNITYLEAVE)]: 'Maternity leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.PAIDLEAVE)]: 'Paid leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.DUETOFORCEMAJEURELEAVE)]: 'Unexpected event leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.CHILDCARELEAVE)]: 'Child care leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.CAREGIVINGLEAVE)]: 'Caregiving leave',
  [enumMessage('benefitBillingPeriod', BenefitBillingPeriod.HOURLY)]: 'Hourly',
  [enumMessage('benefitBillingPeriod', BenefitBillingPeriod.MONTHLY)]: 'Monthly',
  [enumMessage('benefitInvoiceCategory', BenefitInvoiceCategory.BUYERSBENEFIT)]: 'Buyer\'s benefit',
  [enumMessage('benefitInvoiceCategory', BenefitInvoiceCategory.BUYERSCOST)]: 'Buyer\'s cost',
  [enumMessage('contractType', ContractType.B2B)]: 'B2B',
  [enumMessage('contractType', ContractType.SUBCONTRACT)]: 'Subcontract',
  [enumMessage('contractType', ContractType.MANAGEMENT)]: 'Management',
  [enumMessage('contractType', ContractType.UOP)]: 'UOP',
  [enumMessage('contractType', ContractType.UOD)]: 'UOD',
  [enumMessage('contractType', ContractType.UZ)]: 'UZ',
  [enumMessage('bonusType', BonusType.APPRECIATION)]: 'Appreciation bonus',
  [enumMessage('bonusType', BonusType.EMPLOYEEREFERRAL)]: 'Employee referral',
  [enumMessage('bonusType', BonusType.LANGUAGELEARNING)]: 'Language learning',
  [enumMessage('bonusType', BonusType.OTHER)]: 'Other',
  [enumMessage('bonusType', BonusType.TRAININGPACKAGE)]: 'Training package',
  [enumMessage('bonusType', BonusType.REMOTEWORKEQUIPMENT)]: 'Remote work equipment',
  [enumMessage('bonusType', BonusType.BUSINESSTRAVEL)]: 'Business travel',
  [enumMessage('language', Language.ENGLISH)]: 'English',
  [enumMessage('language', Language.OTHER)]: 'Other',
  [enumMessage('quarter', Quarter.Q1)]: 'Q1',
  [enumMessage('quarter', Quarter.Q2)]: 'Q2',
  [enumMessage('quarter', Quarter.Q3)]: 'Q3',
  [enumMessage('quarter', Quarter.Q4)]: 'Q4',
  [enumMessage('vatNotAppliedReason', VatNotAppliedReason.EXEMPTFROMVAT)]: 'Exempt from VAT',
  [enumMessage('vatNotAppliedReason', VatNotAppliedReason.VATNOTAPPLICABLE)]: 'VAT not applicable',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.PL)]: 'Poland',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.SE)]: 'Sweden',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.DE_BW)]: 'Germany - Bavaria',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.DE_HE)]: 'Germany - Hessen',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.CH_ZURYCH)]: 'Switzerland - Zürich',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.UK_ENG)]: 'United kingdom - England',
  [enumMessage('companyRole', CompanyRole.CHIEFOFFICER)]: 'C-level',
  [enumMessage('companyRole', CompanyRole.DIRECTOR)]: 'Director',
  [enumMessage('companyRole', CompanyRole.HEAD)]: 'Head of',
  [enumMessage('companyRole', CompanyRole.MANAGER)]: 'Manager',
  [enumMessage('companyRole', CompanyRole.LEAD)]: 'Lead',
  [enumMessage('companyRole', CompanyRole.EMPLOYEE)]: 'Employee',
} as const;

export default translations;
