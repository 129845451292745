import type { ConditionalPick } from 'type-fest';

import type {
  AbsenceType as AdminAbsenceType,
  AbsenceTypeInput as AdminAbsenceTypeInput,
  CalendarDayType as AdminCalendarDayType,
  OnCallMultiplier as AdminOnCallMultiplier,
  BenefitBuyerType as AdminBenefitBuyerType,
  AdditionalPeopleNumber as AdminAdditionalPeopleNumber,
  BenefitGroupType as AdminBenefitGroupType,
  BonusType as AdminBonusType,
  VatNotAppliedReason as AdminVatNotAppliedReason,
  HolidayCalendarId as AdminHolidayCalendarId,
  EmploymentContractType as AdminEmploymentContractType,
  B2BContractType as AdminB2BContractType,
  CompanyRole as AdminCompanyRole,
} from '../../schema/admin';
import type {
  AbsenceType as UserAbsenceType,
  AbsenceTypeInput as UserAbsenceTypeInput,
  CalendarDayType as UserCalendarDayType,
  OnCallMultiplier as UserOnCallMultiplier,
  BenefitGroupType as UserBenefitGroupType,
  VatNotAppliedReason as UserVatNotAppliedReason,
  HolidayCalendarId as UserHolidayCalendarId,
  EmploymentContractType as UserEmploymentContractType,
  CompanyRole as UserCompanyRole,
  BonusType as UserBonusType,
} from '../../schema/user';

import type { ObjectValues } from '../object';

export type InterpolatedVariables<T extends string, U = undefined> =
  T extends `${infer A}{{${infer B}}}${infer C}`
    ? InterpolatedVariables<A, Record<B, number | string>>
    & InterpolatedVariables<C, Record<B, number | string>>
    : U;

export interface SharedEnumMessages {
  calendarDayType: AdminCalendarDayType | UserCalendarDayType;
  absenceType: AdminAbsenceType | UserAbsenceType;
  absenceTypeInput: AdminAbsenceTypeInput | UserAbsenceTypeInput;
  onCallMultiplier: AdminOnCallMultiplier | UserOnCallMultiplier;
  benefitBuyerType: AdminBenefitBuyerType;
  benefitAdditionalPeople: AdminAdditionalPeopleNumber;
  benefitGroupType: AdminBenefitGroupType | UserBenefitGroupType;
  bonusType: AdminBonusType | UserBonusType;
  b2bContractType: AdminB2BContractType;
  vatNotAppliedReason: AdminVatNotAppliedReason | UserVatNotAppliedReason;
  holidayCalendaryId: AdminHolidayCalendarId | UserHolidayCalendarId;
  companyRole: AdminCompanyRole | UserCompanyRole;
  employmentContractType: AdminEmploymentContractType | UserEmploymentContractType;
}

export const enumMessage = <T extends keyof SharedEnumMessages, U extends SharedEnumMessages[T]>(
  enumName: T,
  value: U,
): `${T}-${U}` => `${enumName}-${value}` as const;

export type SharedMessages = typeof sharedMessages[number];
export type SharedPluralMessages = keyof (typeof sharedPluralMessages);
export type PluralBuildingBlocksToMessages<T extends Record<string, Record<'other', string>>> = ObjectValues<({
  [key in keyof T]: T[key]['other']
})>;

export type Messages = {
  [key in keyof SharedEnumMessages as `${key}-${SharedEnumMessages[key]}`]: undefined
} & {
  [key in PluralBuildingBlocksToMessages<typeof sharedPluralBuildingBlocks>]: InterpolatedVariables<key>
} & {
  [key in SharedMessages]: InterpolatedVariables<key>
} & {
  [key in SharedPluralMessages]: InterpolatedVariables<key>
};

export type MessageKey = keyof Messages;
export type PlainMessageKey = keyof ConditionalPick<Messages, undefined>;

type PluralSuffixes = 'few' | 'many' | 'one' | 'other' | 'two' | 'zero';
type PluralMessages = Readonly<Record<string, Partial<Record<PluralSuffixes, string>>>>;
type PluralMessagesBuildingBlocks = Readonly<Record<string, Partial<Record<Exclude<PluralSuffixes, 'other'>, string>> & Record<'other', string>>>;

export const makePluralMessages = <T extends PluralMessages>(arg: T): T => arg;
export const parsePluralMessages = <T extends PluralMessages>(messages: T): Record<keyof T, string> => {
  const parsed = Object.entries(messages)
    .reduce<Record<string, string>>((acc, [key, pluralVersions]) => {
    Object.entries({ other: key, ...pluralVersions }).forEach(([pluralVersionKey, message]) => {
      acc[`${key}_${pluralVersionKey}`] = message;
    });

    return acc;
  }, {});

  return parsed as Record<keyof T, string>;
};

export const parsePluralMessageBlocks = <T extends PluralMessagesBuildingBlocks>(
  arg: T,
): Record<PluralBuildingBlocksToMessages<T>, string> => parsePluralMessages(arg);

export const sharedPluralBuildingBlocks = makePluralMessages({
  days: {
    one: '{{count}} day',
    other: '{{count}} days',
  },
  hours: {
    one: '{{count}} hour',
    other: '{{count}} hours',
  },
} as const);

export const insertBuildingBlock = <Block extends keyof typeof sharedPluralBuildingBlocks, Name extends string>(
  block: Block,
  variableName: Name,
): `$t(${Block}, {"count": {{${Name}}} })` => `$t(${block}, {"count": {{${variableName}}} })`;

export const sharedPluralMessages = makePluralMessages({
  '{{count}} working days ({{time}})': {
    one: '{{count}} working day ({{time}})',
  },
  '{{count}} working days included': {
    one: '{{count}} working day included',
  },
  '{{count}} entries will be added': {
    one: '{{count}} entry will be added',
  },
  '{{count}} shifts': {
    one: '{{count}} shift',
  },
  'The working time balance should amount to 0 h in a {{count}} months settlement period': {
    one: 'The working time balance should amount to 0 h in a {{count}} month settlement period',
  },
  '{{count}} day shifts will be added.': {
    one: '{{count}} day shift will be added.',
  },
  '{{count}} night shifts will be added.': {
    one: '{{count}} day shift will be added.',
  },
  '{{count}} entries will be deleted from time report.': {
    one: '{{count}} entry will be deleted from time report.',
  },
  'There are no entries in {{count}} working days. Please complete your time report for {{month}} by {{date}}.': {
    one: 'There are no entries in {{count}} working day. Please complete your time report for {{month}} by {{date}}.',
  },
  '{{count}} day shifts': {
    one: '{{count}} day shift',
  },
  '{{count}} night shifts': {
    one: '{{count}} night shift',
  },
  '{{validDayShiftsAmount}} of {{count}} day shifts will be added.': {
    one: '{{validDayShiftsAmount}} of {{count}} day shift will be added.',
  },
  '{{validNightShiftsAmount}} of {{count}} night shifts will be added.': {
    one: '{{validNightShiftsAmount}} of {{count}} night shift will be added.',
  },
});

export const sharedMessages = [
  'This field must be one of {{values}}',
  'This field must be exactly {{length}} characters long',
  'This field must be a valid UUID',
  'This field must be lowercase',
  'This field must be uppercase',
  'This field must be at most {{max}} characters long',
  'This field must be at least {{min}} characters long',
  'This field must be a valid URL',
  'This field must not be empty',
  'This field must be selected from provided options',
  'This field must be a valid {{type}}',
  'This field must be a positive number',
  'This field must be a negative number',
  'This field must be an integer',
  'This field must be less than {{less}}',
  'This field must be more than {{more}}',
  'This field must be at most {{max}}',
  'This field must be at least {{min}}',
  'This field must have at most two decimal places',
  'This field must have at most one decimal place',
  'This field must be in a format of {{format}}',
  'This field must be valid',
  'This field must be a valid IBAN',
  'This field must be a valid BIC/SWIFT',
  'This field must be in a format of X/X',
  'E-mail must be valid',
  'This field must have at least {{min}} values selected',
  'Close profile options menu',
  'Open profile options menu',
  'Log in',
  'Continue with Google',
  'By clicking “Continue with Google” above, you acknowledge that you have read and understood, and agree to NFS’s <termsLink>Terms</termsLink> and <privacyLink>Privacy</privacLink>.',
  'Show previous month',
  'Show next month',
  'Autocomplete for {{fieldName}}',
  'No search results',
  'Select {{name}}',
  'None',
  'Loading',
  'Cancel',
  'Must be valid id number',
  'Must be valid passport number',
  'This is a 404 page, you can\'t hear anything here',
  'Close',
  'Save',
  '{{entityName}} has been added',
  '{{entityName}} has been created',
  '{{entityName}} has been updated',
  '{{entityName}} has been deleted',
  '{{entityName}} has been signed',
  '{{entityName}} has been terminated',
  '{{entityName}} has been assigned',
  '{{entityName}} has been discarded',
  '{{entityName}} has been added to favorites',
  '{{entityName}} has been removed from favorites',
  '{{entityName}} has been generated',
  '{{entityName}} has been rejected',
  '{{entityName}} has been approved',
  '{{entityName}} has been regenerated',
  '{{entityName}} failed to add.',
  '{{entityName}} failed to create.',
  '{{entityName}} failed to update.',
  '{{entityName}} failed to delete.',
  '{{entityName}} failed to sign.',
  '{{entityName}} failed to terminate.',
  '{{entityName}} failed to be assigned.',
  '{{entityName}} failed to be discarded.',
  '{{entityName}} failed to be added to favorites.',
  '{{entityName}} failed to be removed from favorites.',
  '{{entityName}} failed to generate.',
  '{{entityName}} failed to regenerate.',
  '{{entityName}} failed to approve.',
  '{{entityName}} failed to reject.',
  '{{entityName}} has not been added.',
  '{{entityName}} has not been created.',
  '{{entityName}} has not been updated.',
  '{{entityName}} has not been deleted.',
  '{{entityName}} has not been signed.',
  '{{entityName}} has not been terminated.',
  '{{entityName}} has not been assigned.',
  '{{entityName}} has not been discarded.',
  '{{entityName}} has not been added to favorites.',
  '{{entityName}} has not been removed from favorites.',
  '{{entityName}} has not been generated.',
  '{{entityName}} has not been regenerated.',
  '{{entityName}} has not been rejected.',
  '{{entityName}} has not been approved.',
  'No changes have been made in {{entityName}}',
  'Mountains looks ominously',
  'There was a problem loading this data.',
  'Please try again later.',
  'Try again',
  'Soon',
  'Not available on this date',
  'Reporting is blocked on this date',
  'Project',
  'No matching projects',
  'Worked {{percentage}}% out of expected time on {{date}}',
  'Edit absence entry on {{date}}',
  'Empty entry',
  'Edit work entry for {{name}} on {{date}}',
  'Edit on call entry for {{name}} on {{date}}',
  'Add an entry for {{date}}',
  'Overtime',
  'No time set',
  'No end time set',
  'Day shift',
  'Night shift',
  'Editing disabled',
  'Hide weekends',
  'Show full weeks',
  'Days column for {{weekday}}',
  'The timesheet for {{date}} is already blocked. If you see any mistakes, contact your Space Owner ({{spaceOwner}})',
  'The timesheet for {{date}} is blocked for now. It will be enabled in the future',
  'Work record',
  'On call record',
  'Absence record',
  'Add new entry',
  'Edit entry - {{entryType}}',
  'Delete {{absenceType}} added from {{startDate}} to {{endDate}}?',
  'You can\'t undo this action.',
  'Delete entries',
  'Delete',
  'Date',
  'Repeat entry',
  'Until the end of the month ({{date}})',
  'Until the current max reporting date ({{date}})',
  'Select an end date',
  'Repeat until',
  'Additional options',
  'Show / hide additional options',
  'Comment',
  'Enter comment',
  'On-call',
  'days',
  '{{thing}} (optional)',
  '{{thing}} (opt.)',
  'Time',
  'Break',
  'Total',
  'Add the same entry for the next days',
  'Type of shift',
  'Add',
  'Omit weekends and public holidays',
  'Activity',
  'Mark as overtime',
  'Report overtime only with the consent of your manager',
  'The timesheet for {{date}} is already blocked. If you see any mistakes, contact your Space Owner ({{spaceOwner}}',
  'Absence type',
  'Start date',
  'End date',
  'Calendar for {{date}}',
  'Entry modal',
  '{{days}} days of leave will be added (takes {{workingDays}} days from allowance to a total of {{remainingDays}}).',
  'Work',
  'Leave',
  'On-call',
  'No project',
  'Day shift is available on weekends and public holidays',
  'Only night shift is available on weekdays',
  'Select at least one type of shift to add an entry',
  'Until the end of the week ({{date}})',
  'Until the end of the next week ({{date}})',
  'Until the end of the month ({{date}})',
  'Until the current max reporting date ({{date}})',
  'Select an end date',
  'Weekends and public holidays will be omitted',
  'shift',
  'shifts',
  'Shifts details',
  'Set to current time',
  'No stats are available for the selected contract during this time period',
  'Worked time by project (worked {{percentage}}% out of the required time)',
  'Salary',
  'Schedule',
  'Holiday',
  'The day shift on the day before the holiday is billed according to the normal schedule, e.g. not billed on weekdays, {{saturdayDayRate}} rate on Saturday.',
  'Reported time this month',
  'Annual leave this month',
  'Overtime this month',
  'On-call this month',
  'Flex time',
  'expires on {{date}}',
  'Calendar for {{date}}',
  'We were unable to load shift details',
  'Time balance',
  '{{percentage}} of reported work time',
  'No activity',
  'Type of shift',
  'No stats are available for the selected contract during this time period',
  'Out of office this month',
  'Based on reported entries this month.',
  'Salary for {{date}}',
  'Base income',
  'On-call total',
  'Total',
  '{{value}} net',
  '{{value}} gross',
  '{{arg1}} x {{arg2}}',
  'From',
  'To',
  'Selected day is outside of the allowed reporting range for this type',
  'A day must be selected to create an entry',
  'Cannot report work/leave if non-hourly leave already exists on the same day',
  'Cannot report multiple leave entries on the same day',
  'Entry can not be added, because of conflict with exisiting entry.',
  '{{entityName}} already exists',
  '{{entityName}} doesn\'t exists',
  'Position couldn\'t be removed because users are assigned to it',
  'Some fields are read-only',
  'Company type is invalid',
  'Invalid currency',
  'Position is inactive',
  'Can\'t schedule changes in the past',
  'You do not have sufficient permissions',
  'Some fields are invalid ({{fields}})',
  'The page contains outdated data',
  'Reload',
  'Entry can not be added, because it conflicts with an exisiting entry',
  'Work cannot be reported during absence',
  'Total work time would exceed 24h',
  'Entries with intersecting work time exist',
  'Days are not included in the current project\'s reporting period',
  'User is no longer assigned to the selected project',
  '{{validAmount}} of {{totalAmount}} entires will be added.',
  '{{reason}} ({{dates}}).',
  '{{reason}} (day shift: {{dates}}).',
  '{{reason}} (night shift: {{dates}}).',
  '{{reason}} (day shift: {{dayShiftDates}}) (night shift: {{nightShiftDates}}).',
  '{{validDayShiftsAmount}} of {{dayShiftsAmount}} and {{validNightShiftsAmount}} of {{nightShiftsAmount}} will be added.',
  '{{validDayShiftsAmount}} of {{dayShiftsAmount}} and {{validNightShiftsAmount}} will be added.',
  '{{validDayShiftsAmount}} and {{validNightShiftsAmount}} of {{nightShiftsAmount}} will be added.',
  '{{dayShiftsAmount}} and {{nightShiftsAmount}} will be added.',
  'No entries will be added.',
  '{{text}} Invalid entries will be omitted.',
  'On-call with selected type and project already exists',
  'Entry already exists',
  'Contract no longer has an on call rate defined',
  'Details',
  'Cannot add entries during the leave',
  'Cannot add entries outside of reporting range',
  'Go to time report for {{date}}',
  'YYYY.MM.DD',
  'Toggle calendar',
  'Until {{date}}',
  '+{{time}} break',
  'Total has to be greater than 0',
  'Payout',
  'Benefits',
  'Refunded',
  'Not refunded',
  'Each extension in the package is an additional, not-refunded cost (i.e. additional person or sub-benefit).',
  'Refunded base',
  'Luminis products - total',
  'Payroll details',
  'Bonuses',
  'Transfer amount',
  'Tax prepayment',
  'ZUS contribution',
  'PPK amount',
  'There’s nothing here yet - this section will be filled when Finance Department has completed the billing process for the past month.',
  'Appreciation bonus',
  'Employee referral',
  'Language learning',
  'Other',
  'Annual leave settlement',
  'Referral',
  'Appreciation',
  'Language',
  'Annual leave',
  'Other',
  'Total bonuses',
  'Refunded benefits',
  'Not refunded addition',
  'Delete selected entries',
  '{{days}} selected days / {{entries}} entries',
  '{{days}} selected days / {{entries}} entry',
  'Delete the selected entries?',
  'Back to editing',
  'Edit leave entry',
  'There are no work entries to be deleted',
  'There are mutiple separete leave entries selected',
  '+ overtime pay for {{time}}',
  '{{absenceType}} on {{date}}',
  'You cannot add a new entry for today because you have no active contract.',
  'There are no selectable entries in the selected interval',
  'The timesheet for {{yearMonth}} is unavailable as the contract is not active yet this month.',
  'The timesheet for {{yearMonth}} is unavailable due to contract termination on {{terminationDate}}',
  'The timesheet for {{yearMonth}} is blocked for now. It will be enabled in the future.',
  'Annual leave overlapping holiday will not be taken from the allowance',
  'Cannot report leave entries on non-working day',
  'The transfer or invoice status forbids any modifications.',
  'Edit',
  'Effective date {{date}}',
  'Effective date {{date}} ({{description}})',
  'Start month',
  'Conclusion month',
  'Edit benefits from category {{category}}',
  'Paid',
  'Partially refunded',
  'Fully refunded',
  'Edit note',
  'Add note',
  'Paid price: ',
  'Refunded price: ',
  '{{net}} net ({{gross}} gross, {{vatRate}})',
  'Additional people:',
  '{{number}} x Adult',
  '{{number}} x Kids',
  'No benefits assigned',
  'Click to preview',
  'Invoice status',
  'Not delivered',
  'To be checked',
  'Correct',
  'Incorrect',
  'Invoice amount',
  'Total',
  'Number',
  'Invoice details',
  'Enter invoice number',
  'Upload invoice',
  'Status',
  'Invoice',
  'Annual leave summary',
  'Annual leave entitlement',
  'From previous year',
  'Total leave',
  'Days used',
  'Days left',
  'Days To be used by {{date}}',
  'On {{date}}, the employee\'s annual leave entitlement will increase to 26 days per year.',
  'On {{date}}, your annual leave entitlement will increase to 26 days per year.',
  'The employee had no signed contract in {{year}}',
  'You had no signed contract in {{year}}',
  'No entry',
  'Missing work or leave entry',
  'The number of days you have selected exceeds your annual leave entitlement this year.',
  'Entries',
  'You can\'t add leave entry on non-working days.',
  '(takes {{days}} days from allowance)',
  'Work entries exist on {{dates}}',
  'Leave has been already reported on {{dates}}',
  'Training package',
  'Work equipment',
  'A negative flextime balance will reduce the payout for this month.',
  'There are no entries on {{days}} {{month}}. Please complete your time report for {{month}} by {{date}}.',
  'Invoice form',
  'Delete entry',
  'Delete {{absenceType}} added for {{date}}?',
  'We have just updated the app',
  'Reload the page to apply these updates',
  'Reload',
  'No leave entry will be added.',
  'Click to copy',
  'Copied',
  'Selected activity has no project assigned by default.',
  'Selected activity does not allow the work entry to be marked as overtime',
  'Selected activity does not allow the work entry to be repeated',
  'You have {{time}} of {{workTag}} left this year.',
  '{{totalAllowed}} of {{totalRequested}} will be added.',
  'The number of hours you have provided exceeds your {{workTag}} entitlement this year.',
  'Basic pay',
  'base',
  'The user has no Training package hours left. Make sure Head (SO) approved this exception.',
  'Valid from {{date}}',
  'Standard working time exceeded by {{count}}',
  'Start typing or select',
  'Use "{{value}}"',
  'The transfer status forbids any modifications.',
  '{{absenceType}} cannot be reported on days with work entries',
  '{{absenceType}} cannot start/end with non-working days',
  '{{absenceType}} cannot coexsit with already reported leave entry types',
  '{{absenceType}} cannot be reported on days with on-call entries',
  'Absence cannot be reported on days with work entries',
  '{{absenceType}} cannot start/end with non-working days',
  'Absence has been already reported',
  '{{absenceType}} cannot consist of only non-working days',
  'Days would exceed the assigned limit',
  'Invoices',
  'Add invoice',
  'Note',
  'Note or comment',
  'Invoice upload for {{month}} is not unlocked yet.',
  'You will be able to upload new invoices from {{start}} to {{end}}.',
  'Missing invoice file',
  'No invoice for this month',
  'Contact the Finance Department.',
  'Missing invoice number',
  'Invoices for {{period}}',
  'Invoice for {{period}}',
  'Pending',
  'Invoice actions',
  'Marked fields are required',
  'The invoice was checked by a Financial Specialist and marked as correct. You cannot replace the file.',
  'The invoice is waiting for approval from a Financial Specialist. You can still change the file to another version.',
  'The invoice was checked by a Financial Specialist and marked as invalid. You cannot replace the file yourself. A Financial Specialist will contact you.',
  'Correct',
  'Incorrect',
  'To be checked',
  'No uploaded invoice',
  'Previous invoices',
  'Current invoice',
  'Invoices list',
  'Contact your Space owner or Finance department to provide the missing invoice',
  'See all invoices',
  'There’s nothing here yet',
  'Drawer with invoices',
  'Edit invoice',
  'Invoice entry for {{invoiceName}}',
  'Invoice entry',
  'Current invoices',
  'Invoice for {{date}}',
  'Invoice {{number}}',
  'Invoice for {{date}} has been uploaded',
  'No invoice for {{date}} has been uploaded',
  'Upload invoice',
  'Invoice number for {{date}}',
  'Invoices list',
  'Invoice number container buttons',
  'On-call entries cannot be reported on the same days as leave',
  'Paid leave this month',
  '{{days}} used in {{year}}',
  'Summary for {{date}}',
  'Summary',
  'Additional pay',
  'Luminis products',
  'Basic part of the package',
  'Extended part of the package',
  'Effective rate',
  `${insertBuildingBlock('days', 'count')}`,
  `${insertBuildingBlock('days', 'count')} of leave will be added (takes ${insertBuildingBlock('days', 'allowanceTaken')} from allowance to a total of {{allowanceRemaining}}).` as const,
  `{{count}} of ${insertBuildingBlock('days', 'totalAmount')} will be added (takes ${insertBuildingBlock('days', 'allowanceTaken')} from allowance to a total of {{allowanceRemaining}})` as const,
  `${insertBuildingBlock('days', 'count')} will be added` as const,
  `{{count}} of ${insertBuildingBlock('days', 'totalAmount')} will be added` as const,
  '{{count}} of leave will be added (takes {{allowanceTaken}} from allowance to a total of {{allowanceRemaining}}).',
  '{{count}} of {{totalAmount}} will be added (takes {{allowanceTaken}} from allowance to a total of {{allowanceRemaining}})',
  '{{count}} will be added',
  '{{count}} of {{totalAmount}} will be added',
  `${insertBuildingBlock('days', 'count')} of leave will be added (absence can be compensated with the appropriate number of working hours on any other day).` as const,
  `You have ${insertBuildingBlock('days', 'days')} of annual leave from previous year to take before {{date}}` as const,
  `${insertBuildingBlock('days', 'count')} taken from allowance` as const,
  '{{time}} left in {{year}}' as const,
  '{{time}} used in {{year}}' as const,

  `You have ${insertBuildingBlock('days', 'availableAmount')} of the leave left. This request uses ${insertBuildingBlock('days', 'allowanceTaken')}, leaving you with ${insertBuildingBlock('days', 'allowanceRemaining')} this year.` as const,
  `${insertBuildingBlock('days', 'amountTaken')} will be added` as const,
  `${insertBuildingBlock('days', 'amountTaken')} of ${insertBuildingBlock('days', 'totalAmount')} will be added` as const,
  'You have {{availableAmount}} of the leave left. This request uses {{allowanceTaken}}, leaving you with {{allowanceRemaining}} this year.',
  '{{amountTaken}} will be added',
  '{{amountTaken}} of {{totalAmount}} will be added',
  'Total requires a valid time range',
  'Must be a valid time range',
  'Non-working day',
  'Cannot create work entries on non-working days',
  'Visible in admin view only',
  'User\'s note',
  'Today',
  'Go to today',
  'Remote work',
  'Whole day',
  'hours',
  'Entry can not be added, because total work time for this day would exceed 24h',
  'Can\'t change a multiday leave into a hourly one',
  'Can\'t change the leave\'s whole day status while other entries exist',
  'Reporting limit for this absence type has been expended',
  'Exceeds daily leave limit',
  'Exceeds max hours per day',
  'Whole day leave cannot coexist with work entries',
  'Work cannot be reported together with a whole day absence record',
  'Entry can not be added because whole day leave has aleady been reported',
  'This change is a result of an edit made in the variant. It can be removed on the benefits page',
  'absence to be made up on another day',
  'holiday, max. 20 or 26 days, 100% paid',
  'if you need more holiday, not paid',
  'birth of a child, wedding, funeral etc., 100% paid',
  'due to health reasons, 80% paid',
  'for under 14 y/o child care, max. 16 hours or 2 days, 100% paid',
  'for a family or household member\'s care, max. 5 days, not paid',
  'for urgent matters, max. 16 hours or 2 days, 50% paid',
  'for managers only, 100% paid',
  '{{count}} of work included',
  '{{value}} VAT',
  'Reported work time',
  'h',
  'm',
  'out of {{time}}',
  '{{time}} left in {{month}}',
  'total in {{date}}',
  'this month',
  'Out of office',
  'Hours with a special rate. Report only with the consent of your manager.',
  'Time report preferences',
  'Calendar',
  'Work time insights',
  'Finances',
  'Structure of the reported time',
  'There’s nothing here yet - structure will appear when there’ll be hours reported for this month.',
  '{{time}} overtime',
  '{{time}} missing as of today',
  '{{time}} missing',
  '{{time}} exceeding',
  'Flextime balance is correct',
  'Your flex time balance is correct as of today',
  'You can take these hours off by <strong>{{date}}</strong>',
  'Remember to make up for the missing hours by <strong>{{date}}</strong>',
  'Today\'s status',
  'remote work this month',
  'Hours to calculate remote work refund.',
  'No bonuses were awarded this month',
  'No Luminis products to show.',
  'Direct reporting',
  'Practice',
  'Operational',
  'Reporting for this period in this project is not yet open. Please try again soon when the next periods will be available.',
  'Transfer',
  'Leave & training',
  'Limited leave {{year}}',
  'Leave {{year}}',
  'Unlimited leave {{year}}',
  'max. {{days}}',
  'max. {{days}} / {{hours}}',
  'max. {{days}} this year',
  '{{time}} used',
  '{{time}} left',
  'Training package {{year}}',
  'Training time',
  'max. {{amount}} this year',
  '{{amount}} used',
  '{{amount}} left',
  'max. {{amount}}',
  'Not assigned',
  'Select the user\'s primary project to indicate his operational lead.',
  'Selected primary project has no lead assigned.',
  'Direct reporting · line structure',
  '{{start}} - no end date',
  'Planned',
  'Position {{number}}',
  'Projects',
  'No assigned projects',
  'This change is a result of changes in the assigned project.',
  'Hidden',
  'Salary for {{date}} is not in pending state',
  'Project marked as primary by default.',
  'Direct subordinates',
  'Transfer deduction',
  'To be used by {{date}}',
  'Total',
  'Used',
  'Left',
  'Annual leave (hours)',
  'Annual leave (days)',
  '{{time}} taken from allowance',
  'No leave used in {{year}}',
  'Primary project',
  'Export CSV',
  'There was an error while downloading the file.',
  'Export CSV for this month',
  'Space owner',
  'Practice lead',
  'Operational lead',
  'Hours would exceed daily work limit. ({{time}})',
  'Must be a whole hour',
  'Next month',
  'Previous month',
  'Pending leave approval',
  'Pending overtime approval',
  'Approved',
  'by {{name}}',
  'Space {{name}}',
  'Project {{name}}',
  '8 h standard comparison',
  'Reported work time',
  'Working days x 8h',
  'Reported work time + absence time - expected work time',
  'Public holidays are not included',
  'Reported absence',
  'Ended',
  'Active',
  'Internal',
  'Space and position history',
  'Position',
  'Space',
  'Now',
] as const;

export default sharedMessages;
